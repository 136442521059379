import React, { useEffect, useState } from "react";
import axios from "axios";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, loop } from "swiper/modules";

const baseURL = "https://admin.ambienceventures.com";

const Brand = () => {
  const [brand, setBrand] = useState([]);

  useEffect(() => {
    axios.get(`${baseURL}/api/bannerlist`).then((res) => {
      setBrand(res.data.data);
    });
  }, []);
  return (
    <>
      <div
        className="brand-five px-0"
        style={{ background: "rgb(191, 213, 148)", padding: " 50px" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-12 wow fadeIn"
              data-wow-duration="1.5s"
              data-wow-delay=".1s"
            >
              <div className="heading-one text-center">
                <span className="heading-one-subtitle">Our Partners</span>
                <h2 className="heading-one-title">Trusted by Top Brands</h2>
              </div>
            </div>
          </div>
          <Swiper
            spaceBetween={10}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 50,
              },
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper row align-items-center"
          >
            {brand.map((item, index) => {
              return (
                <SwiperSlide>
                  <img
                    src={`${baseURL}/public/upload/banner/${item.image}`}
                    alt="Brand-Image"
                    style={{ width: "80px" }}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Brand;
