import React from "react";
import { Link } from "react-router-dom";
import {
  AiFillInstagram,
  AiFillLinkedin,
  AiFillFacebook,
} from "react-icons/ai";
import { CiLocationOn, CiPhone, CiMail } from "react-icons/ci";

const Footer = ({ data }) => {
  return (
    <>
      <div className="footer-three">
        <hr />
        <div className="newsletter-two">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="newsletter-two-inner">
                  <div
                    className="heading-one wow fadeIn"
                    data-wow-duration="1.5s"
                    data-wow-delay=".1s"
                  >
                    <span className="heading-one-subtitle">
                      Subscribe Our Newsletter
                    </span>
                    <h2 className="heading-one-title">Get Regular Updates</h2>
                    {/* <p className="heading-one-text">
                      Kimod tempoer incididunt onomes sundo ritoma amar Lorem
                      ipsum dolor sit amet, consectetur adipimod
                    </p> */}
                  </div>

                  <form
                    action="#"
                    className="footer-widget-form wow fadeIn"
                    data-wow-duration="1.5s"
                    data-wow-delay=".3s"
                  >
                    <input type="text" placeholder="Your email here" />
                    <button className="btn btn-style-one" type="submit">
                      {" "}
                      <span>Subscribe</span>{" "}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="footer-two py-4">
          <div className="container">
            <div className="row mb-n30 justify-content-between">
              <div
                className="col-lg-3 col-md-3 col-sm-6 mb-30 wow fadeIn"
                data-wow-duration="1.5s"
                data-wow-delay=".1s"
              >
                <div className="footer-widget-two">
                  <div className="logo negative-margin">
                    <Link to="/">
                      <img
                        src="assets/images/logo-2.png"
                        alt=""
                        style={{ width: "155px" }}
                      />
                    </Link>
                  </div>
                  <div>
                    <p className="mt-3">
                      At AMBIENCE, we view scents as reflections of individual identity. Our mission is to infuse spaces with fragrances that embody personal style and character.
                    </p>
                  </div>
                </div>
              </div>
              <div className="widget-list-single col-md-2 col-6">
                <h5 className="footer-widget-two-title">Quick links</h5>
                <ul className="footer-widget-two-list p-0">
                  {data?.map((item, index) => (
                    <li key={index}>
                      <Link to={item.url}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="widget-list-single col-md-2 col-6">
                <h5 className="footer-widget-two-title">Products</h5>
                <ul className="footer-widget-two-list p-0">
                  {data[2].submenu?.map((item, id, index) => (
                    <li key={id}>
                      <Link to={`${item.id}`}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div
                className="col-lg-3 col-md-3 col-12 mb-30 wow fadeIn"
                data-wow-duration="1.5s"
                data-wow-delay=".1s"
              >
                <div className="footer-widget-two">
                  <h5 className="footer-widget-two-title">GET IN TOUCH</h5>
                  <ul className="footer-widget-two-list-icon p-0">
                    <li>
                      <CiLocationOn style={{ fontSize: "25px" }} />
                      <span>
                        Krishna Niwas, Old Dombivli, Dombivli West -421202
                      </span>
                    </li>
                    <li>
                      <Link to="mailto:info@ambienceventures.com">
                        <CiMail />
                        <span>info@ambienceventures.com</span>{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="tel:+91 9137502289">
                        <CiPhone />
                        <span>+91 9137502289</span>{" "}
                      </Link>
                    </li>
                  </ul>
                  <ul className="social-icon d-flex flex-start p-0">
                    <li>
                      <Link to="https://www.facebook.com/profile.php?id=61560793577501&mibextid=ZbWKwL"
                        className="facebook"
                        target="_blank"
                      >
                        <AiFillFacebook />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.linkedin.com/company/ambience-ventures/"
                        className="linkedin"
                        target="_blank"
                      >
                        <AiFillLinkedin />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.instagram.com/ambienceventures?utm_source=qr&igsh=YXlsN21ieHVqODQ1"
                        className="instagram"
                        target="_blank"
                      >
                        <AiFillInstagram style={{ color: "#f11d71" }} />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="footer-two pb-2">
          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <div className="">
                  <p className="text-center">
                    2024<span> © ambienceventures.com</span>
                  </p>

                  {/* <ul className="footer-two-bootm-list order-md-2 order-1">
                    <li>
                      <a href="">Terms &amp; Condition</a>
                    </li>
                    <li>
                      <a href="/">Privecy Policy</a>
                    </li>
                    <li>
                      <a href="/">Legal</a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div
          className="row z9"
          style={{ position: "fixed", bottom: 50, width: "100%" }}
        >
          <div className="col "></div>
          <div className="col ">
            <a href="https://wa.me/9137502289">
              <img
                className="d-flex ml-auto"
                style={{ width: "45px", marginLeft: "auto" }}
                src="assets/w.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
