import React from "react";
import { Link } from "react-router-dom";

const ProductCard = (props) => {
  return (
    <>
      <div
        className="col mb-30 wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay=".1s"
      >
        <div className="blogpost">
          <div className="blogpost-image">
            <Link to={`${props.id}`}>
              <img src={props.img} alt="BlogImage" />
            </Link>
            <div className="pa bg-g h-100 w-100 p-3 d-flex">
              <p style={{ alignSelf: "flex-end" }}>
                <b className="text-light">
                  {props.title}
                  <br />
                  {props.urls ? (
                    <span style={{ color: "#165faa" }}>Know More</span>
                  ) : null}
                </b>
              </p>
              <div>
                {props.subtitle ? (
                  <div class="newsCaption">
                    <p className="text-light">
                      <b>{props.title}</b>
                    </p>
                    <p className="text-light">{props.subtitle}</p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* <div className="blogpost-content">
            <h4 className="blogpost-title">{props.title}</h4>
            <p>{props.subtitle}</p>
            {props.button
              ? props.button?.map((item, index) => (
                  <div className="text-center">
                    <Link
                      to={`${props.id}`}
                      className="blogpost-read-more text-center"
                    >
                      {item.text}
                    </Link>
                  </div>
                ))
              : null}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ProductCard;
