import React from "react";
import Heading from "./Heading";
import { Link } from "react-router-dom";

const About = ({ data }) => {
  return (
    <>
      <div className="section-padding about-two">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 wow fadeIn"
              data-wow-duration="1.5s"
              data-wow-delay=".1s"
            >
              <div className="about-two-images me-0">
                <video
                  className="videoTag"
                  autoPlay
                  loop
                  muted
                  style={{ width: "100%", maxHeight: "100%" }}
                >
                  <source src="assets/about.mp4" type="video/mp4" />
                </video>
                {/* <div className="about-two-images-one">
                  <img className="js-tilt w-75" src={data.img} alt="about" style={{borderRadius : "15px"}}/>
                </div>
                <div className="about-two-images-two" style={{textAlign : "right"}}>
                  <img className="js-tilt w-50" src={data.img2} alt="about" style={{borderRadius : "15px"}}/>
                </div> */}
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeIn"
              data-wow-duration="1.5s"
              data-wow-delay=".3s"
            >
              <div className="heading-one">
                <Heading
                  classNameName=""
                  title={data.title}
                  subtitle={data.name}
                />

                <div className="about-two-text">
                  <p>{data.description}</p>
                  <p>{data.description1}</p>
                  <p>
                    <b>Our Vision : </b>
                    {data.OurVision}
                  </p>
                  <Link to="/contact_us" className="btn-style-one">
                    <span>Contact Us</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
