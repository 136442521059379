import React from "react";
import Breadcrumb from "../components/Breadcrumb";
const liveData = [
  {
    id: 1,
    img: "assets/images/live_p/1.jpg",
    title: "Make your own perfume with Live perfumer in your event",
    subtitle: "Turning Emotions into Fragrance 💖",
    description:
      "Make your special day even more memorable with our live fragrance stalls and wedding favors that leave a fragrant impression. ",
    description2:
      "Contact us today to turn your emotions into fragrance and make your wedding day unforgettable!",
    data: [
      {
        id: 1,
        img: "",
        title: "Live Fragrance Stalls",
        description:
          "Immerse your guests in a sensory experience they won't forget. Our live fragrance stalls offer a variety of scents to choose from, allowing guests to select their favorites and create lasting memories.",
      },
      {
        id: 2,
        img: "",
        title: "Wedding Favours",
        description:
          "Leave a lasting impression on your guests with wedding favors that evoke emotion through fragrance. Our carefully curated scents will ensure your guests remember your special day long after it's over, creating a truly magical experience.",
      },
    ],
  },
];
const gallData = [
  {
    id: 1,
    img: "assets/images/live_p/0.jpeg",
  },
  {
    id: 2,
    img: "assets/images/live_p/2.jpeg",
  },
  {
    id: 3,
    img: "assets/images/live_p/3.jpeg",
  },
  // {
  //   id: 4,
  //   img: "assets/images/live_p/4.jpeg",
  // },
  // {
  //   id: 5,
  //   img: "assets/images/live_p/5.jpeg",
  // },
  {
    id: 6,
    img: "assets/images/live_p/6.jpeg",
  },
];
const LivePerfumer = () => {
  return (
    <>
      <Breadcrumb title="Live Perfumer" />
      <div className="quality section-padding">
        <div className="container">
          {liveData.map((item, index) => (
            <div className="row align-items-center">
              <div
                className="col-lg-6 wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".1s"
              >
                <div className="">
                  <img className="js-tilt" src={item.img} alt="QualityImage" style={{ borderRadius: "15px" }} />
                </div>
              </div>
              <div
                className="col-lg-6 px-md-5 wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="heading-one">
                  <h4 className="mb-3">
                    {item.title}
                  </h4>
                  <span className="heading-one-subtitle">{item.subtitle}</span>
                  <p>{item.description}</p>
                </div>
                <div className="accordion about-accordion" id="faqone">
                  <div className="accordion-item faq-accordion-item">
                    <h2
                      className="accordion-header faq-accordion-header"
                      id="headingTwo"
                    >
                      <button
                        className="accordion-button faq-accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        {item.data[0].title}
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse faq-accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#faqone"
                    >
                      <p className="accordion-body">
                        {item.data[0].description}
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item faq-accordion-item">
                    <h2
                      className="accordion-header faq-accordion-header"
                      id="headingThree"
                    >
                      <button
                        className="accordion-button faq-accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        {item.data[1].title}
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse faq-accordion-collapse collapse show"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqone"
                    >
                      <p className="accordion-body">
                        {item.data[1].description}
                      </p>
                    </div>
                  </div>
                </div>
                <p>{item.description2}</p>
              </div>
            </div>
          ))}
          {/* <div className="row">
            {gallData.map((item, index) => (
              <div className="col-md-3 mt-4">
                <img className="w-100" src={item.img} alt=""  />
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default LivePerfumer;
