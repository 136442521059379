import React from "react";

const Heading = (props) => {
  return (
    <>
      <span className="heading-one-subtitle">{props.subtitle}</span>
      <h2 className="heading-one-title">{props.title}</h2>
    </>
  );
};

export default Heading;
