import React from "react";
import { Link } from "react-router-dom";
const Hero = ({ data }) => {
  return (
    <>
      <div className="hero" style={{ position: "relative" }}>
        <video
          className="videoTag"
          autoPlay
          loop
          muted
          style={{ width: "100%" }}
        >
          <source src="assets/hero.mp4" type="video/mp4" />
        </video>
        <div
          style={{
            background: "#00000094",
            position: "absolute",
            top: "0",
            height: "100%",
            width: "100%",
          }}
        ></div>
        <div
          className="container"
          style={{
            position: "absolute",
            top: "0",
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="row align-items-center">
            <div className="col-md-2"></div>
            <div className="col-lg-8">
              <div className="hero-content pt-md-5 mt-md-5 pt-4 text-center">
                <h2 className="title text-light mt-5 m-f">{data.title}</h2>
                <p className="text text-light p-f">{data.description}</p>
                <p className="text text-light p-f">{data.description1}</p>
                <div className="mt-md-5 mt-3">
                  <Link
                    to="/about_us"
                    className="btn-style-one ones mt-md-5 mt-0"
                  >
                    <span>Read More</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
