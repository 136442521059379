import { Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import Home from "./pages/Home";
import About_us from "./pages/About_us";
import Contacts from "./pages/Contacts";
import Event from "./pages/Event";
import Product from "./pages/Product";
import "bootstrap/dist/css/bootstrap.min.css";
import SignatureScent from "./pages/SignatureScent";
import Scenting from "./pages/Scenting";
import LivePerfumer from "./pages/LivePerfumer";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "swiper/css";

function App() {
  const navData = [
    {
      id: 1,
      name: "Home",
      url: "/",
      hero: [
        {
          id: 1,
          img: "",
          subtitle: "We Are AMBIENCE",
          title: "Grow Your Business with AMBIENCE",
          text: "At AMBIENCE, we view scents as reflections of individual identity. ",
          button: [
            {
              id: "1",
              text: "Get Started",
              url: "/a",
            },
            {
              id: "2",
              text: "Plans Pricing",
              url: "/b",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "About Us",
      url: "/about_us",
      img: "assets/images/a2.jpg",
      img2: "assets/images/a3.jpeg",
      title: "Welcome to Ambience",
      OurVision:
        "At AMBIENCE, we envision a community united by an appreciation for exceptional scents and an understanding of fragrance's profound impact on vibes around. We believe that smelling good equates to feeling good, and our scents are crafted to promote and create vibes around you.",
      description1:
        "We specialize in fragrance products designed to tap into the mind's mood-altering potential. Through carefully crafted blends and unique pairings, we offer an array of options to suit every preference.",
      description:
        "At AMBIENCE, we view scents as reflections of individual identity. Our mission is to infuse spaces with fragrances that embody personal style and character.",
    },

    {
      id: 3,
      name: "Products",
      url: "1",
      submenu: [
        {
          id: 1,
          img: "assets/images/products/product_3/1.jpg",
          img2: "assets/images/products/product_3/a1.jpg",
          name: "Mars - 2010D",
          subTitle: "Applicable space: 600m3",
          // list: [
          //   {
          //     id: 1,
          //     img: "assets/images/icon/checked.png",
          //     list: "Simple design, coordinate with various space.",
          //   },
          //   {
          //     id: 2,
          //     img: "assets/images/icon/checked.png",
          //     list: "High-performance atomizer, low noise, balanced atomization volume",
          //   },

          //   {
          //     id: 3,
          //     img: "assets/images/icon/checked.png",
          //     list: "Plug-in atomizer, easy to replace/maintain. 200ml capacity canister, built-in fan accelerates diffusion.",
          //   },
          //   {
          //     id: 4,
          //     img: "assets/images/icon/checked.png",
          //     list: "Adjustable work/pause time and period, concentration.",
          //   },
          // ],

          capacity: "Capacity: 200ml",
          // power: "Voltage/Power: DC12V/10.2W",
          // noise: "Noise: 45dba",
          // nw: "N.W: 1.2kg",
          // moq: "MOQ: 20 units",
          // dimension: "Dimension: W165mm D229mm H102mm",
          // oilconsumption: "Oil Consumption: 2.5ml/h±5% ",
          // coverage: "Coverage: 600m3",
          // applicableplace:
          //   "Room / Clothing Store / Home Furnishing Hall / Meeting Room / Health Hall",
          // Color: "Color: White / Black WHITE BLACK",
          imgVar: [
            {
              id: 1,
              img: "assets/images/products/product_3/2.jpg",
            },
            {
              id: 2,
              img: "assets/images/products/product_3/3.jpg",
            },
          ],
        },
        {
          id: 2,
          img: "assets/images/products/product_1/1.jpg",
          img2: "assets/images/products/product_1/a2.jpg",
          name: "Mercury - 41 - B",
          subTitle: "Applicable space: 300m3",
          // list: [
          //   {
          //     id: 1,
          //     img: "assets/images/icon/checked.png",
          //     list: "Beauliful, simple, coordinate with various space.",
          //   },
          //   {
          //     id: 2,
          //     img: "assets/images/icon/checked.png",
          //     list: "Adjustable work/pause time and work period. concentration.",
          //   },

          //   {
          //     id: 3,
          //     img: "assets/images/icon/checked.png",
          //     list: "New high—performance atomizer, low noise, balanced atomization volume. ",
          //   },
          //   {
          //     id: 4,
          //     img: "assets/images/icon/checked.png",
          //     list: "Plug-in atomizer, easy to replace / maintain.",
          //   },
          //   {
          //     id: 5,
          //     img: "assets/images/icon/checked.png",
          //     list: "Built-in fan accelerates diffusion.",
          //   },
          // ],
          capacity: "Capacity: 2OO ml",
          // power: "Voltage/Power: DC12V7 6W",
          // noise: "Noise'	3 6dba ",
          // nw: "N.W: 0.F1kg",
          // moq: "MOQ: 30 units",
          // dimension: "Dimension: W 47'mm +D84mm+H2O2mm",
          // oilconsumption: "Oil Consumption: O.79mI7hz5 % ",
          // coverage: "Coverage: 3OOm* ",
          // applicableplace:
          //   "Applicable place: Room / Corridor / Elevator Hall / Toilet ",
          Color: "Color: White / Black",
          imgVar: [
            {
              id: 1,
              img: "assets/images/products/product_1/2.jpg",
            },
            {
              id: 2,
              img: "assets/images/products/product_1/3.jpg",
            },
          ],
        },
        {
          id: 3,
          img: "assets/images/products/product_2/1.jpg",
          img2: "assets/images/products/product_2/a3.jpg",
          name: "Venus - 580",
          subTitle: "Applicable space: 3000m*",
          // list: [
          //   {
          //     id: 1,
          //     img: "assets/images/icon/checked.png",
          //     list: "Equipped with high-performance atomizer and powerful siIenl fan. ",
          //   },
          //   {
          //     id: 2,
          //     img: "assets/images/icon/checked.png",
          //     list: "Two—fluid nano—alomizalion lechnology. the fragrance diffuses evenly.",
          //   },

          //   {
          //     id: 3,
          //     img: "assets/images/icon/checked.png",
          //     list: "Built-in intelligent PCB control program, can be connected to HVAC system. ",
          //   },
          //   {
          //     id: 4,
          //     img: "assets/images/icon/checked.png",
          //     list: "Button control or WIFI -APP control(optional).",
          //   },
          // ],
          capacity: "Capacity: 750 ml",
          // power: "Voltage/Power: DCC 2V/  11.MW",
          // noise: "Noise: “ 43dba",
          // nw: "N.W: 2.05 kg",
          // moq: "MOQ: 6 unils",
          // dimension: "Dimension: W 223mm+D 10amm+ H 282 mm",
          // oilconsumption: "Oil Consumption: 3mI/h15 %",
          // coverage: "Coverage: 3OOOm*  ",
          // applicableplace:
          //   "Applicable place: Conference Room / Clothing Store / KTV / Sales Lobby / Home Furnishing Hall / Chain Hotel Lobby",
          // Color: "Color: White",
          imgVar: [
            {
              id: 1,
              img: "assets/images/products/product_2/2.jpg",
            },
            {
              id: 2,
              img: "assets/images/products/product_2/3.jpg",
            },
            {
              id: 3,
              img: "assets/images/products/product_2/4.jpg",
            },
          ],
        },
        {
          id: 4,
          img: "assets/images/products/product_6/1.jpg",
          img2: "assets/images/products/product_6/a4.jpg",
          name: "Saturn - DU - 160",
          subTitle: "Applicable space: 5,000-15,O0Om*",
          // list: [
          //   {
          //     id: 1,
          //     img: "assets/images/icon/checked.png",
          //     list: "Equipped with dual atomizers/compressors/panels, which can be controlled independently, connect to HCAC system for use. ",
          //   },
          //   {
          //     id: 2,
          //     img: "assets/images/icon/checked.png",
          //     list: "•	Classic, high performance device, built in high quality, long life compressor.",
          //   },

          //   {
          //     id: 3,
          //     img: "assets/images/icon/checked.png",
          //     list: "•	Industry-leading atomization structure design ensures accurate and stable diffusion, and prevents essential oils from deterioration.",
          //   },
          //   {
          //     id: 4,
          //     img: "assets/images/icon/checked.png",
          //     list: "•	5OOmI standard capacity, can be optionally matched with 5L bucket.",
          //   },
          // ],
          capacity: "Capacity: 5OO ml+ 2/5L• 2",
          // power: "Voltage/Power: DC12V/16W",
          // noise: "Noise: 5 45dba",
          // nw: "N.W: 6. 9k8",
          // moq: "MOQ: 4 units",
          // dimension: "Dimension: W3OOmm•D165mm•H220mm",
          // oilconsumption: "Oil Consumption: 5mI/h15 Oil Consumption: 5mI/h15 %",
          // coverage: "Coverages 5,000-15,0OOm*",
          // applicableplace:
          //   "Applicable place: Hotels	/	Airports	/	Office Buildin8s /	Cinemas	/	High-end Shopping Malls	/Entertainment Clubs",
          // Color: "Color: Black",
          imgVar: [
            {
              id: 1,
              img: "assets/images/products/product_6/2.jpg",
            },
            {
              id: 2,
              img: "assets/images/products/product_6/3.jpg",
            },
          ],
        },
        {
          id: 5,
          img: "assets/images/products/product_5/1.jpg",
          img2: "assets/images/products/product_5/a5.jpg",
          name: "Earth - 1010",
          subTitle: "Applicable space: 5000m3",
          // list: [
          //   {
          //     id: 1,
          //     img: "assets/images/icon/checked.png",
          //     list: "The separate assembly parts is convenient for installation in various space.",
          //   },
          //   {
          //     id: 2,
          //     img: "assets/images/icon/checked.png",
          //     list: "Classic, high-performance product, built in high-quality, long life compressor.",
          //   },

          //   {
          //     id: 3,
          //     img: "assets/images/icon/checked.png",
          //     list: "•	Industry-leading atomization structure design ensures accurate and stable diffusion, and prevents essential oils from deterioration.",
          //   },
          //   {
          //     id: 4,
          //     img: "assets/images/icon/checked.png",
          //     list: "Built-in intelligent PCB control program, can be connected to HVAC system",
          //   },
          // ],
          capacity: "Capacity: 5OO ml",
          // power: "Voltage/Power: AC 22OV/ 11W",
          // noise: "Noise:	<=50 dba",
          // nw: "N.W: 5.35kg",
          // moq: "MOQ: 4 units",
          // dimension: "DDimension: W 220mm•Dl 55mm•H23Omm",
          // oilconsumption: "Oil Consumplion: Xml/h+5 %",
          // coverage: "Coverage: 5OOOm* ",
          // applicableplace:
          //   "Applicable place: Hotels / Airports / Office Buildings / Cinemas / High-end Shopping Malls / Entertainmenl Clubs",
          // Color: "Color: Black",
          imgVar: [
            {
              id: 1,
              img: "assets/images/products/product_5/2.jpg",
            },
            {
              id: 2,
              img: "assets/images/products/product_5/3.jpg",
            },
          ],
        },
        {
          id: 7,
          img: "assets/images/products/product_7/1.jpg",
          img2: "assets/images/products/product_7/a7.jpg",
          name: "Jupiter A316",
          subTitle: "Applicable space: 	900 m2 / 2700 m3 / 95310 ft3",
          list: [
            {
              id: 1,
              img: "assets/images/icon/checked.png",
              list: "Built-In Fan, Fast Diffusion",
            },
            {
              id: 2,
              img: "assets/images/icon/checked.png",
              list: "Working Mode Customization",
            },

            {
              id: 3,
              img: "assets/images/icon/checked.png",
              list: "Bluetooth APP Control",
            },
            {
              id: 5,
              img: "assets/images/icon/checked.png",
              list: "Super-Fine Mist Room Fragrance Diffuser",
            },
          ],

          // capacity: "Coverage: 	900 m2 / 2700 m3 / 95310 ft3",
          // power: "Voltage/Powers DCC ZV/13W",
          // noise: " Noise: <=47'dba ",
          // nw: "N.W: 7.6kg",
          // moq: "MOQ: 4 units",
          // dimension: "Dimension: W2OOmm•D1 ZOmm+ H 560mm",
          // oilconsumption: "Oil Consumption: 4mI/h15 %",
          // coverage: "Coverages 3OOOm* ",
          // applicableplace:
          //   "Applicable place: Shopping Mall / Bar / Entenainment Center / Shopping Center/ Hotel Meeting Room",
          // Color: "Color: Black",
          imgVar: [
            {
              id: 1,
              img: "assets/images/products/product_7/2.jpg",
            },
            {
              id: 2,
              img: "assets/images/products/product_7/3.jpg",
            },
          ],
        },
        {
          id: 6,
          img: "assets/images/products/product_4/1.jpg",
          img2: "assets/images/products/product_4/a6.jpg",
          name: "Moon - 460C",
          subTitle: "Applicable space: 3000m3",
          list: [
            {
              id: 1,
              img: "assets/images/icon/checked.png",
              list: "Equipped with high-performance atomizer, powerful silent fan.",
            },
            {
              id: 2,
              img: "assets/images/icon/checked.png",
              list: "Using two-fluid micro-nano atomization technology, built-in PCB intelligent control program.",
            },

            {
              id: 3,
              img: "assets/images/icon/checked.png",
              list: "Innovative atomization structure design 1o prevent the delerioration of essential oil and ensure 1he continuous and stable diffusion.",
            },
            {
              id: 4,
              img: "assets/images/icon/checked.png",
              list: "All-metal, simple design, easy to operate.",
            },
          ],
          capacity: "Capacity: 750 ml",
          // power: "Voltage/Powers DCC ZV/13W",
          // noise: " Noise: <=47'dba ",
          // nw: "N.W: 7.6kg",
          // moq: "MOQ: 4 units",
          // dimension: "Dimension: W2OOmm•D1 ZOmm+ H 560mm",
          // oilconsumption: "Oil Consumption: 4mI/h15 %",
          // coverage: "Coverages 3OOOm* ",
          // applicableplace:
          //   "Applicable place: Shopping Mall / Bar / Entenainment Center / Shopping Center/ Hotel Meeting Room",
          // Color: "Color: Black",
          imgVar: [
            {
              id: 1,
              img: "assets/images/products/product_4/2.jpg",
            },
            {
              id: 2,
              img: "assets/images/products/product_4/3.jpg",
            },
            {
              id: 3,
              img: "assets/images/products/product_4/4.jpg",
            },
          ],
        },
      ],
    },
    {
      id: 5,
      name: "Event",
      url: "/event",
    },
    {
      id: 6,
      name: "Live Perfumer",
      url: "/l_perfumer",
    },
    {
      id: 8,
      name: "Contact Us",
      url: "/contact_us",
    },
  ];

  return (
    <Routes>
      <Route path="/" element={<Layout data={navData} />}>
        {" "}
        <Route index element={<Home data={navData} />} />
        <Route path="/about_us" element={<About_us data={navData[1]} />} />
        <Route path="/event" element={<Event />} />{" "}
        <Route path="/scenting" element={<Scenting />} />{" "}
        <Route path="/product/:id" element={<SignatureScent />} />{" "}
        <Route path="/signatureScent/:id" element={<SignatureScent />} />{" "}
        <Route path="/l_perfumer" element={<LivePerfumer />} />{" "}
        <Route path="/:id" element={<Product data={navData[2]} />} />
        <Route path="/contact_us" element={<Contacts />} />{" "}
      </Route>{" "}
    </Routes>
  );
}

export default App;
