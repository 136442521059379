import React from "react";
import { Link } from "react-router-dom";

const Whyscent = ({ choose, mh126 }) => {
  return (
    <>
      <div className="container">
        {choose.map((item, index) => (
          <div key={index}>
            <div className="row justify-content-center">
              <div
                className="col-md-6 wow fadeIn text-center"
                data-wow-duration="1.5s"
                data-wow-delay=".1s"
              >
                <div className="blog-post-three-top">
                  <div className="mx-auto">
                    <h2 className="heading-one-title">{item.title}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 mb-n30">
              {item.data.map((item, index) => (
                <div
                  key={item.id}
                  className="col mb-30 wow fadeIn "
                  data-wow-duration="1.5s"
                  data-wow-delay=".1s"
                >
                  <Link
                    href="/"
                    className={`service-four-single ${mh126}`}
                  >
                    <div className="service-four-single-top">
                      <div className="icon">
                        <img className="icon-1" src={item.img1} alt="icon" />
                        <img
                          className="icon-2"
                          src={item.img}
                          alt="iconGradient"
                        />
                      </div>
                      <h4 className="title">{item.title}</h4>
                    </div>
                    <div className="service-four-single-text">
                      <p>{item.details}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div >
    </>
  );
};

export default Whyscent;
