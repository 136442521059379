import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import About from "../components/About";

const About_us = ({ data }) => {
  return (
    <>
      <Breadcrumb title="About Us" />
      <About data={data} />
      {/* <TeamSection /> */}
    </>
  );
};

export default About_us;
