import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = (props) => {
  return (
    <>
      <div
        className="section-padding breadcrumb"
        style={{
          backgroundImage: "url(../assets/hb.webp)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 align-items-center text-center">
              <div className="breadcrumb-wrapper">
                <h1 className="title">{props.title}</h1>
                <ul className="breadcrumb-list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>{props.title}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
