import React from "react";
import EventCard from "../components/EventCard";
import Breadcrumb from "../components/Breadcrumb";
import Whyscent from "../components/Whyscent";
import { Link } from "react-router-dom";

const eventData = [
  {
    id: 1,
    subtitle:
      "Make your event unforgettable by leasing our top-notch scenting equipment. This budget-friendly option includes hassle-free setup, complimentary fragrances, and ultimate flexibility. Ensure your guests remember your event for all the right reasons.",
    title: "Ready to Elevate Your Event with Scenting?",
    title_2:
      "Transform Your Special Event into an Extraordinary Sensory Experience!",
    title_3: "Lease Scenting Equipment for Your Special Event",
    img: "assets/images/cs.webp",
    button: [
      {
        id: 1,
        text: "CALL NOW",
        url: "tel:+91123456789",
      },
    ],
  },
];
const choose = [
  {
    id: 1,
    title: "Why Choose Our Scenting Equipment?",
    data: [
      {
        id: 1,
        img: "assets/images/service/home-3/icon2.png",
        img1: "assets/images/service/home-3/icon2-2.png",
        title: "Hassle-Free Setup",
        details:
          "Our team takes care of everything, so you can focus on your event.",
      },
      {
        id: 2,
        img: "assets/images/service/home-3/icon5.png",
        img1: "assets/images/service/home-3/icon5-2.png",
        title: "Complimentary Fragrances",
        details: "Choose from a variety of premium scents at no extra cost.",
      },
      {
        id: 3,
        title: "Ultimate Flexibility",
        img: "assets/images/service/home-3/icon4.png",
        img1: "assets/images/service/home-3/icon4-2.png",
        details:
          "Perfect for any event size or type, with customizable options to meet your needs.",
      },
    ],
  },
];
const Event = () => {
  return (
    <>
      <Breadcrumb title="Event" />
      <div className="section-padding blog-grid mb-5 pb-md-4">
        <div className="container">
          <div className="row">
            <div className="col-12"></div>
          </div>
          <div className="row cols-lg-12">
            {eventData.map((Data, index) => (
              <EventCard
                key={index}
                subtitle={Data.subtitle}
                title={Data.title}
                title_2={Data.title_2}
                title_3={Data.title_3}
                img={Data.img}
              />
            ))}
          </div>
        </div>
      </div>
      <Whyscent choose={choose} mh126="mh125" />

      <div className="blog-post section-padding mt-5" style={{ background: "rgb(191, 213, 148)" }}>
        <div className="container">
          <div className="row   align-items-center">
            <div className="blog-post-top col-md-8">
              <div
                className="heading-one wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".1s"
              >
                <h2 className="heading-one-title">Contact Us Today!</h2>
                <p>
                  <b> Request a free, no-obligation quote tailored to your event's
                    unique requirements. Email us now and let’s start planning how
                    to create lasting memories.</b>
                </p>
                <h2 className="heading-one-title">
                  Don’t Miss This Opportunity!
                </h2>
                <p>
                  <b>
                    Make your event truly unforgettable. Let's create memories
                    that last a lifetime.
                  </b>
                </p>
              </div>
            </div>
            <div className="col-md-4 text-right" style={{ textAlign: "end" }}>
              <Link
                to="/contact_us"
                className="btn-style-one wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".3s"
              >
                <span>Contact Us</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Event;
