import React from "react";
import Heading from "./Heading";
import ProductCard from "./ProductCard";
import OwlCarousel from "react-owl-carousel";


const options = {
  loop: true,
  items: 7,
  margin: 20,
  autoplay: true,
  dots: false,
  // autoplayTimeout: 800,
  // slideTransition: "linear",
  // autoplaySpeed: 8000,
  // fluidSpeed: true,
  // smartSpeed: 3000,
  slideTransition: "linear",
  autoplayTimeout: 6000,
  autoplaySpeed: 6000,
  nav: false,
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 6,
    },
  },
};

const Service = ({ data }) => {
  return (
    <>
      <div className="section-padding ">
        <div className="container">
          <div className="row">
            <div
              className="col-12 wow fadeIn"
              data-wow-duration="1.5s"
              data-wow-delay=".1s"
            >
              <div className="heading-one text-center mb-md-5 mb-3">
                <Heading
                  className=""
                  title="What We Offer to Our Clients"
                  subtitle="Products"
                />
              </div>
            </div>
          </div>
          <OwlCarousel
            id="customer-testimonoals"
            className="owl-carousel owl-theme"
            {...options}
          >
            {data.length === 0 ? (
              <div class="item">
                <div class="shadow-effect"></div>
                <div class="testimonial-name"></div>
              </div>
            ) : (
              data.map((item, index) => {
                return (
                  <ProductCard
                    key={index}
                    img={item.img2}
                    title={item.name}
                    id={item.id}
                  />
                );
              })
            )}
          </OwlCarousel>
        </div>
      </div>
    </>
  );
};

export default Service;
