import React from "react";
import ProductCard from "../components/ProductCard";
import Breadcrumb from "../components/Breadcrumb";
import Centing from "../components/Centing";
import Heading from "../components/Heading";
import { useParams } from "react-router-dom";
const sentSapce = [

  {
    id: 1,
    title: "Scent in Workplace",
    img: "/assets/images/1.jpg",
    description: "Corporate offices are more than just places of work – they are environments where employees spend a significant portion of their time, striving to be productive, creative, and engaged. As businesses recognize the importance of employee well-being and satisfaction in driving productivity and retention, attention is turning to the role of the physical workspace in fostering a positive and conducive atmosphere. Scenting in corporate offices is emerging as a powerful tool to enhance the workplace environment and promote employee wellness. Let's delve into why scenting is becoming a popular choice for forward-thinking companies.",
    list: [
      {
        id: 1,
        img: "",
        title: "Enhancing Mood and Productivity",
        img: "/assets/images/why/work1.webp",
        Description: "Scent has a direct impact on our emotions and mood. By infusing the workplace with carefully selected fragrances, companies can create an environment that promotes focus, concentration, and productivity. For example, energizing scents like citrus or peppermint can help employees feel more alert and focused, while calming scents like lavender or chamomile can promote relaxation and reduce stress. By creating a pleasant and stimulating atmosphere, scenting can help employees feel more motivated and engaged in their work.",
      },
      {
        id: 2,
        title: "Improving Air Quality",
        Description:
          ": In addition to influencing mood and productivity, scenting can also help improve indoor air quality in corporate offices. Many commercial air fresheners contain essential oils that not only add fragrance to the air but also possess antibacterial and antifungal properties. By diffusing these natural scents into the air, companies can help neutralize odors and reduce the presence of airborne pathogens, creating a healthier and more hygienic workplace environment for employees.",
      },
      {
        id: 3,
        title: "Fostering a Positive Company Culture",
        img: "/assets/images/why/work2.webp",
        Description:
          "The physical environment of the workplace plays a significant role in shaping company culture and employee morale. Scenting can be used as a tool to reinforce the company's values and create a sense of belonging among employees. For example, a company that values innovation and creativity may choose to infuse the office with stimulating scents like citrus or peppermint to inspire new ideas and spark creativity. By aligning the scent of the workplace with the company's ethos, companies can foster a positive and cohesive company culture that resonates with employees.",
      },
      {
        id: 4,
        title: "Enhancing the Client Experience",
        Description:
          "In addition to benefiting employees, scenting can also enhance the experience of clients and visitors to the corporate office. A welcoming and pleasant fragrance in the reception area can create a positive first impression and set the tone for meetings and interactions. By creating a sensory experience that delights both employees and visitors alike, companies can strengthen their brand image and leave a lasting impression on clients and stakeholders.",
      },
    ],
  },
  {
    id: 2,
    title: "Scent in Hospitality - Hotels & Resorts",
    img: "assets/images/2.jpg",
    description:
      "Introduction: In the fiercely competitive hospitality industry, where hotels vie for the attention and loyalty of discerning travelers, every aspect of the guest experience plays a crucial role in shaping perceptions and driving satisfaction. One often-overlooked element that can make a significant impact is scenting – the strategic use of fragrances to create a welcoming and memorable ambiance. Let's explore why scenting in hotels is not just a luxury but a strategic investment in guest satisfaction and brand differentiation.",
    list: [
      {
        id: 1,
        title: "Creating a Memorable Arrival",
        img: "/assets/images/why/hot1.webp",
        Description: "The moment a guest steps into a hotel lobby sets the tone for their entire stay. By infusing the air with a carefully curated fragrance, hotels can create an immediate sensory impression that sets them apart from competitors. Whether it's a refreshing burst of citrus in a beachfront resort or a warm, inviting scent in a luxury city hotel, the right fragrance can evoke feelings of comfort, relaxation, and anticipation, signaling to guests that they are about to embark on a memorable experience.",
      },
      {
        id: 2,
        title: "Enhancing the Guest Experience",
        Description:
          "Scent has a powerful impact on our emotions and mood. By incorporating pleasant fragrances throughout the hotel – from the lobby to the guest rooms – hotels can create a welcoming and immersive environment that delights the senses and enhances the overall guest experience. Whether it's the calming scent of lavender in a spa or the invigorating aroma of eucalyptus in a fitness center, the right fragrance can evoke specific moods and emotions, helping guests relax, rejuvenate, and enjoy their stay to the fullest.",
      },
      {
        id: 3,
        title: "Reinforcing Brand Identity",
        img: "/assets/images/why/hot2.webp",
        Description:
          "Just as a hotel's decor, amenities, and service standards reflect its brand identity, so too can scent. A signature fragrance can serve as a subtle yet powerful cue that reinforces brand recognition and loyalty. When guests encounter the same scent across different touchpoints – from the lobby to the guest rooms to the hotel's amenities – it creates a cohesive brand experience that leaves a lasting impression and sets the hotel apart from competitors.",
      },
      {
        id: 4,
        title: "Promoting Well-being",
        Description:
          "In an age where wellness and self-care are increasingly important to travelers, hotels can leverage scenting to promote a sense of well-being and relaxation. Certain fragrances, such as lavender, chamomile, and vanilla, have been shown to have calming and stress-relieving properties, making them ideal for creating a tranquil atmosphere in guest rooms and spa facilities. By incorporating these fragrances into their environment, hotels can help guests unwind, de-stress, and enjoy a restful night's sleep.",
      },
    ],
  },
  {
    id: 3,
    title: "Scent in Hospitals",
    img: "assets/images/3.jpg",
    description: "Hospitals are places of healing, where patients seek treatment and care during times of illness and vulnerability. While medical expertise and advanced technology are essential components of healthcare facilities, the physical environment also plays a significant role in patient well-being and recovery. Scenting in hospitals has emerged as a promising strategy for creating a more comforting and therapeutic atmosphere, with numerous benefits for patients, staff, and visitors alike. Let's explore why scenting is gaining recognition as an integral part of the healthcare experience.",
    list: [
      {
        id: 1,
        title: "Easing Patient Anxiety",
        img: "/assets/images/why/hosp1.webp",
        Description:
          "Entering a hospital can be an intimidating experience for patients, often accompanied by feelings of fear, anxiety, and uncertainty. Scenting can help alleviate these negative emotions by creating a calming and reassuring atmosphere. By infusing the air with soothing scents like lavender or chamomile, hospitals can help patients feel more relaxed and at ease during their stay, promoting a sense of comfort and well-being that supports the healing process.",
      },
      {
        id: 2,
        title: "Masking Unpleasant Odors",
        Description:
          "Hospitals are not always associated with pleasant smells, as the presence of disinfectants, medications, and bodily fluids can contribute to unpleasant odors. Scenting can help mask these malodors and create a more pleasant and inviting environment for patients, staff, and visitors. By diffusing fresh and clean scents like citrus or eucalyptus, hospitals can neutralize unpleasant odors and create a more hygienic and welcoming atmosphere throughout the facility.",
      },
      {
        id: 3,
        title: "Improving Staff Morale",
        img: "/assets/images/why/hosp2.webp",
        Description:
          "In addition to benefiting patients, scenting can also have a positive impact on hospital staff. Working in a healthcare environment can be emotionally and physically demanding, and staff members often face high levels of stress and burnout. Scenting can help improve staff morale and well-being by creating a more pleasant and uplifting work environment. By infusing the air with energizing scents like peppermint or rosemary, hospitals can help staff feel more refreshed and rejuvenated during long shifts, leading to improved job satisfaction and retention.",
      },
      {
        id: 4,
        title: "Enhancing the Patient Experience",
        Description:
          "Patient experience is increasingly recognized as a key driver of healthcare quality and outcomes. Scenting can contribute to a more positive patient experience by engaging the senses and creating a more holistic healing environment. Whether it's the comforting scent of freshly laundered linens or the subtle aroma of flowers in a hospital garden, the right fragrance can evoke feelings of warmth, safety, and compassion, helping patients feel more cared for and supported during their hospital stay.",
      },
      {
        id: 5,
        title: "Supporting Healing and Recovery",
        Description:
          "Research has shown that scent can have a profound impact on physical and emotional well-being, with certain fragrances possessing therapeutic properties that promote healing and recovery. For example, lavender has been shown to reduce anxiety and improve sleep quality, while peppermint can help alleviate nausea and headaches. By incorporating these healing scents into the hospital environment, healthcare facilities can complement traditional medical treatments and support patients' overall health and well-being.",
      },
    ],
  },
  {
    id: 4,
    title: "Scent in Retail & Malls",
    img: "assets/images/4.jpg",
    description: "In the bustling world of retail, where competition is fierce and consumer attention spans are fleeting, every element of the shopping experience matters. From the store layout to the quality of customer service, retailers are constantly seeking innovative ways to engage shoppers and leave a lasting impression. One such method that has gained traction in recent years is scenting – the strategic use of fragrances to enhance the ambiance of retail spaces.",
    list: [
      {
        id: 1,
        title: "Emotional Connection",
        img: "/assets/images/why/mall1.webp",
        Description:
          "Scent has a profound impact on our emotions and memories. By harnessing the power of scent, retailers can create a multisensory experience that resonates with customers on a deeper level.",
      },
      {
        id: 2,
        title: "Brand Identity",
        Description:
          "Just as a logo or color scheme helps to define a brand's identity, so too can scent. A signature fragrance can serve as a subtle yet powerful cue that reinforces brand recognition and loyalty. When customers encounter the same scent across different touchpoints – from the storefront to the fitting rooms – it reinforces the brand's image and creates a cohesive brand experience that sets it apart from competitors.",
      },
      {
        id: 3,
        title: "Influence on Behavior",
        img: "/assets/images/why/mall2.webp",
        Description:
          "Studies have shown that scent can influence consumer behavior in various ways. Pleasant fragrances have been found to increase dwell time, encourage exploration of products, and even enhance perceived product quality. For example, the aroma of lavender may promote relaxation, while citrus scents can evoke feelings of freshness and vitality, influencing purchase decisions in favor of products that align with these associations.",
      },
      {
        id: 4,
        title: "Creating Ambiance",
        Description:
          "In an age where online shopping offers convenience and efficiency, physical retailers must offer something more – an experience that cannot be replicated online. Scenting plays a crucial role in creating a welcoming and immersive environment that captivates shoppers' senses and encourages them to stay and explore. Whether it's a warm, inviting scent in a cozy boutique or a refreshing fragrance in a bustling mall, the right scent can transform a mundane shopping trip into a memorable sensory journey.",
      },
    ],
  },
  {
    id: 5,
    title: "Scent in Gym & Wellness Centers",
    img: "assets/images/5.jpg",
    description:
      "Gyms and wellness centers are spaces dedicated to promoting health, fitness, and well-being. In these environments, every detail matters, from the equipment and programs offered to the ambiance and atmosphere. Scenting has emerged as a popular strategy for enhancing the gym and wellness center experience, with numerous benefits for both clients and staff. Let's explore why scenting is becoming a staple in fitness facilities and how it contributes to a more invigorating and motivating environment.",
    list: [
      {
        id: 1,
        title: "Boosting Motivation and Focus",
        img: "/assets/images/why/gym1.webp",
        Description:
          "Exercise requires not only physical effort but also mental focus and determination. Scenting can help boost motivation and focus by creating an energizing and invigorating atmosphere in the gym. Scents like citrus, peppermint, and eucalyptus have been shown to increase alertness and improve cognitive performance, making them ideal for enhancing the workout experience. By infusing the air with these stimulating fragrances, gyms can help clients feel more motivated and focused during their workouts, leading to improved performance and satisfaction.",
      },
      {
        id: 2,
        title: "Enhancing the Exercise Experience",
        Description:
          "The gym experience is not just about the physical exertion of exercise but also the overall ambiance and atmosphere. Scenting can enhance the exercise experience by engaging the senses and creating a more enjoyable and immersive environment. Whether it's the crisp, clean scent of freshly laundered towels or the invigorating aroma of a tropical breeze, the right fragrance can evoke feelings of energy, vitality, and well-being, enhancing the overall enjoyment of the workout."
      },
      {
        id: 3,
        title: "Masking Unpleasant Odors",
        img: "/assets/images/why/gym2.webp",
        Description:
          "Like any public space, gyms and wellness centers can sometimes be prone to unpleasant odors, such as sweat and body odor. Scenting can help mask these malodors and create a more pleasant and inviting environment for clients and staff. By diffusing fresh and clean scents like citrus or lavender, gyms can neutralize unpleasant odors and create a more hygienic and enjoyable atmosphere throughout the facility, ensuring that clients can focus on their workout without distraction.",
      },
      {
        id: 4,
        title: "Promoting Relaxation and Recovery",
        Description:
          ": In addition to energizing scents, gyms and wellness centers can also benefit from incorporating relaxing and soothing fragrances into their environment. Scents like lavender, chamomile, and sandalwood have been shown to promote relaxation and reduce stress, making them ideal for calming the mind and body after a strenuous workout. By creating a tranquil and rejuvenating atmosphere, gyms can help clients unwind, recover, and recharge, supporting their overall health and well-being.",
      },
    ],
  },
  {
    id: 6,
    title: "Scent in Airports",
    img: "assets/images/5.jpg",
    description:
      "Airports are bustling hubs of activity, where travelers embark on journeys, reunite with loved ones, and experience the excitement of exploration. In this fast-paced environment, where stress and anxiety can run high, airports are increasingly turning to scenting as a means of enhancing the passenger experience and creating a more welcoming and calming atmosphere. Let's explore why scenting in airports is gaining popularity and how it contributes to a smoother and more enjoyable travel experience.",
    list: [
      {
        id: 1,
        title: "Easing Traveler Stress",
        img: "/assets/images/why/ar1.webp",
        Description:
          "Traveling can be a stressful experience, with long queues, security checks, and flight delays adding to the pressure. Scenting can help alleviate traveler stress by creating a soothing and calming atmosphere throughout the airport. By infusing the air with calming scents like lavender or chamomile, airports can help passengers feel more relaxed and at ease as they navigate through the terminal, reducing stress levels and improving the overall travel experience.",
      },
      {
        id: 2,
        title: "Enhancing the Ambiance",
        Description:
          "Airports are more than just transportation hubs – they are often the first and last impression that travelers have of a destination. Scenting can be used to enhance the ambiance of the airport, creating a more pleasant and memorable environment for passengers. Whether it's the fresh scent of citrus in a tropical airport or the comforting aroma of baked goods in a bustling international terminal, the right fragrance can evoke a sense of place and enhance the overall atmosphere of the airport.",
      },
      {
        id: 3,
        title: "Branding and Identity",
        img: "/assets/images/why/ar2.webp",
        Description:
          "Scenting can also be used as a tool for branding and identity within airports. Just as hotels and retail stores use signature fragrances to reinforce their brand image, airports can incorporate scenting as part of their branding strategy. For example, an airport located in a coastal city might use ocean-inspired scents to evoke a sense of relaxation and vacation, while an airport in a cultural hub might use exotic spices or floral scents to reflect the local culture and heritage.",
      },
      {
        id: 4,
        title: "Promoting Revenue Generation",
        Description:
          "Scenting can also have a tangible impact on airport revenue generation. Studies have shown that pleasant scents can influence consumer behavior, encouraging passengers to spend more time and money in airport shops and restaurants. By creating a welcoming and inviting atmosphere through scenting, airports can increase dwell time and encourage passengers to explore and patronize the various retail and dining establishments within the terminal, ultimately boosting revenue for airport operators and concessionaires.",
      },
    ],
  },
];
const SignatureScent = () => {
  const { id } = useParams();

  return (
    <>
      {sentSapce
        .filter((item) => item.id == id)
        .map((item, index) => (
          <Breadcrumb key={index} title={item.title} />
        ))}
      <div className="speciality">
        <div className="container">
          {sentSapce
            .filter((item) => item.id == id)
            .map((item, index) => (
              <Centing
                key={index}
                item={item.id}
                title={item.title}
                img={item.img}
                description={item.description}
                list={item.list}
                data={sentSapce}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default SignatureScent;
