import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import { useParams } from "react-router";
import { Link, NavLink } from "react-router-dom";

const Product = ({ data }) => {
  const { id } = useParams();
  return (
    <>
      {data.submenu
        .filter((item) => item.id == id)
        .map((item, index) => (
          <Breadcrumb key={index} title={item.name} />
        ))}

      <div className="section-padding service-details">
        <div className="container">
          {data.submenu
            .filter((item) => item.id == id)
            .map((item, id) => (
              <>
                <div className="service-details-content" key={id}>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="service-details-image">
                        <img src={item.img} alt="product" className="w-100" />
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-6">
                      <div className="service-details-key mt-0">
                        <h4 className="service-details-title mt-0 pt-0">
                          {item.name}
                        </h4>
                        <p className="service-details-text1">
                          <b></b> {item.subTitle}
                        </p>

                        <ul className="service-details-content-list">
                          {item.list?.map((item, index) => (
                            <li key={index}>
                              <img src={item.img} alt="CheckIcon" />
                              <p>{item.list}</p>
                            </li>
                          ))}
                        </ul>

                        <div className="row">
                          <div className="col-md-3">
                            <p>
                              <b>{item.capacity}</b>
                            </p>
                            <p>
                              <b>{item.nw}</b>
                            </p>
                            <p>
                              <b>{item.moq}</b>
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p>
                              <b>{item.power}</b>
                            </p>
                            <p>
                              <b>{item.oilconsumption}</b>
                            </p>
                            <p>
                              <b>{item.dimension}</b>
                            </p>
                          </div>
                          <div className="col-md-3">
                            <p>
                              <b>{item.noise}</b>
                            </p>
                            <p>
                              <b>{item.coverage}</b>
                            </p>
                            <p>
                              <b></b>
                            </p>
                          </div>
                        </div>
                        <p className="text">
                          <b>{item.applicableplace}</b>
                        </p>
                        <p className="text mb-0">
                          <b>{item.Color}</b>
                        </p>

                        <div className="row mb-n30">
                          {item.imgVar.map((item, index) => (
                            <div className="col-xl-4 col-lg-4 col-md-4 col-4 mb-30">
                              <div className="service-details-image2">
                                <img
                                  kry={index}
                                  className="w-100"
                                  src={item.img}
                                  alt="ServiceImage"
                                />
                              </div>
                            </div>
                          ))}

                          <div className="mt-3">
                            <Link
                              to="tel:+91 9137502289"
                              className="btn-style-one"
                            >
                              <span>CALL NOW</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  );
};

export default Product;
