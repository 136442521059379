import React from "react";

const EventCard = (props) => {
  return (
    <>
      <div className="speciality ">
        <div className="container">
          <div className="row align-items-center mb-n60">
            <div
              className="col-lg-6 mb-60 wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay=".1s"
            >
              <div className="">
                <img className="js-tilt" src={props.img} alt="speciliity" style={{ borderRadius: "15px" }} />
              </div>
            </div>
            <div
              className="col-lg-6 px-md-5 mb-60 wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay=".3s"
            >
              <div className="heading-one">
                <h4 className="">
                  {props.title}
                </h4>
                <p className="mt-3">
                  <b>
                    <b>{props.title_2}</b>
                  </b>
                </p>
                <p className="mt-3">
                  <b>
                    <b>{props.title_3}</b>
                  </b>
                </p>
                <p className="mt-3">{props.subtitle}</p>
              </div>
              <div className="standard-blog-bottom">
                {props.button
                  ? props.button?.map((item, index) => (
                    <a href={item.url} className="btn-style-one">
                      <span>{item.text}</span>
                    </a>
                  ))
                  : null}
                { }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCard;
