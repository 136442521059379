import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { IoMdClose } from "react-icons/io";

const Header = ({ data }) => {
  const [isActive, setIsActive] = useState(false);

  const toggleText = () => {
    setIsActive((current) => !current);
  };

  return (
    <>
      <header className="header-wrapper-two header-four sticky-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="flex-center justify-content-between">
                <div className="logo">
                  <Link to="/">
                    <img
                      className="logo-2"
                      src="/assets/images/logo-2.png"
                      alt="logoImage"
                      style={{ width: " 148px" }}
                    />
                  </Link>
                </div>

                <div className="flex-center header-right">
                  <div className="d-none d-lg-flex header-right_menu">
                    <nav className="main-menu">
                      <ul
                        style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                      >
                        {data?.map(({ index, name, url, submenu }) => (
                          <li className="has-children" key={index}>
                            <NavLink to={url}>{name}</NavLink>
                            {submenu ? (
                              <ul className="sub-menu">
                                {submenu?.map(({ name, id, url }) => (
                                  <li key={id}>
                                    <Link to={`${id}`}>{name}</Link>
                                  </li>
                                ))}
                              </ul>
                            ) : null}
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                  <div className="header-right_actions flex-center">
                    <div className="hamburger d-block d-lg-none">
                      <a
                        className="header-action-btn header-action-btn-menu hamburger_button d-flex"
                        href="#/"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <div className="logo">
            <Link to="/">
              <img src="assets/images/logo-2.png" alt="" />
            </Link>
          </div>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <IoMdClose />
          </button>
        </div>
        <div className="offcanvas-body">
          <nav>
            <ul className="mobile-menu p-0">
              {data?.map(({ index, name, url, submenu }) => (
                <li className="has-children" key={index}>
                  {submenu ? (
                    <>
                      <NavLink
                        className="mob-text"
                        to={url}
                        onClick={toggleText}
                      >
                        {name} {"      "}
                        {!isActive ? <>+</> : <>-</>}
                      </NavLink>

                      <ul
                        className={isActive ? "d-block sub-menu" : "d-none"}
                        onClick={toggleText}
                      >
                        {submenu?.map(({ name, id, url }) => (
                          <li key={id}>
                            <Link className="dropdown-item f-600" to={`${id}`}>
                              <span
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                {name}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <NavLink to={url} className="mob-text">
                      <span data-bs-dismiss="offcanvas" aria-label="Close">
                        {name}
                      </span>
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
