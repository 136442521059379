import React, { useState } from "react";
import Heading from "./Heading";
import { CiLocationOn, CiPhone, CiMail } from "react-icons/ci";
import { Link } from "react-router-dom";
import axios from "axios";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    // Add other form fields here
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [error, setError] = useState("");
  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create FormData object
    const dataToSubmit = new FormData();
    for (const key in formData) {
      dataToSubmit.append(key, formData[key]);
    }

    // Axios POST request
    try {
      const response = await axios({
        method: "post",
        url: "https://admin.ambienceventures.com/api/contactus",
        data: dataToSubmit,
        headers: { "Content-Type": "application/json" },
      });
      setResponseMessage(response.data.savecontact);
      setError(response.data.errors);
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      <div className="contact-us section-margin-top">
        <div className="container position-relative">
          <div
            className="row wow fadeIn"
            data-wow-duration="1.5s"
            data-wow-delay=".1s"
          >
            <div className="heading-one text-center">
              <Heading subtitle="Contact Us" title="Stay Connected" />
            </div>
          </div>
          <div className="row mb-n30">
            <div
              className="col-md-6 mb-30 wow fadeIn"
              data-wow-duration="1.5s"
              data-wow-delay=".1s"
            >
              <div className="heading-one">
                <h4 className="">Fill the form for a call back</h4>
              </div>
              <p>{ }</p>
              <ul className="contact-info">
                <li>
                  <div className="icon">
                    <CiLocationOn style={{ fontSize: "30px" }} />
                  </div>
                  <div className="contact-info-content">
                    <h3 className="title">Address</h3>
                    <span className="text">
                      Krishna Niwas, Old Dombivli, Dombivli West -421202
                    </span>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <CiPhone style={{ fontSize: "30px" }} />
                  </div>
                  <div className="contact-info-content">
                    <h3 className="title">Phone</h3>
                    <span className="text">
                      <Link to="tel:+919137502289">+91 9137502289</Link>
                    </span>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <CiMail style={{ fontSize: "30px" }} />
                  </div>
                  <div className="contact-info-content">
                    <h3 className="title">Email</h3>
                    <span className="text">
                      <Link to="mailto:info@ambienceventures.com">
                        info@ambienceventures.com
                      </Link>
                    </span>
                  </div>
                </li>
              </ul>
            </div>

            <div
              className="col-md-6 mb-30 wow fadeIn"
              data-wow-duration="1.5s"
              data-wow-delay=".3s"
            >
              <form className="contact-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    {error?.name ? (
                      <div
                        className="alert alert-sm alert-danger d-flex align-items-center py-1"
                        role="alert"
                      >
                        <div>{error.name}</div>
                      </div>
                    ) : null}
                    <input
                      type="text"
                      placeholder="Your name"
                      value={formData.name}
                      onChange={handleChange}
                      name="name"
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    {error?.email ? (
                      <div
                        className="alert alert-sm alert-danger d-flex align-items-center py-1"
                        role="alert"
                      >
                        <div>{error.email}</div>
                      </div>
                    ) : null}
                    <input
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Your email"
                      name="email"
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    {error?.phone ? (
                      <div
                        className="alert alert-sm alert-danger d-flex align-items-center py-1"
                        role="alert"
                      >
                        <div>{error.phone}</div>
                      </div>
                    ) : null}
                    <input
                      type="number"
                      pattern="^-?[0-9]\d*\.?\d*$"
                      placeholder="Your phone"
                      value={formData.phone}
                      onChange={handleChange}
                      name="phone"
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    {error?.subject ? (
                      <div
                        className="alert alert-sm alert-danger d-flex align-items-center py-1"
                        role="alert"
                      >
                        <div>{error.subject}</div>
                      </div>
                    ) : null}
                    <input
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleChange}
                      name="subject"
                    />
                  </div>
                  <div className="col-12 mb-3">
                    {error?.message ? (
                      <div
                        className="alert alert-sm alert-danger d-flex align-items-center py-1"
                        role="alert"
                      >
                        <div>{error.message}</div>
                      </div>
                    ) : null}
                    <textarea
                      name="message"
                      type="text"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Write your comment here"
                    ></textarea>
                  </div>
                  <div className="col-12 text-left">
                    {responseMessage ? (
                      <div className="alert alert-success" role="alert">
                        {responseMessage}
                      </div>
                    ) : null}

                    <button className="btn btn-style-one" type="submit">
                      {" "}
                      <span>Submit Now</span>{" "}
                    </button>
                  </div>
                  <div className="col-12">
                    <div className="form-message mt-4"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
