import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Centing from "../components/Centing";

const Scenting = () => {
  const sentSapce = [
    {
      id: 1,
      title: "Scent in Workstation",
      img: "assets/images/1.jpg",
      description: "",
      list: [
        {
          id: 1,
          title: "Enhance the Working Environment",
          Description: "Create a more inviting and comfortable workspace.",
        },
        {
          id: 2,
          title: "Increase Performance & Loyalty",
          Description:
            "Boost productivity and connect with employees by reducing stress and promoting focus.",
        },
        {
          id: 3,
          title: "Add Unique Signature Elements",
          Description:
            "Reinforce your brand identity with a distinctive, memorable scent.",
        },
      ],
    },
    {
      id: 2,
      title: "Scent in Hospitals",
      img: "assets/images/3.jpg",
      description:
        "Strategic scenting in hospitals enhances the healing environment and patient experience.",
      list: [
        {
          id: 1,
          title: "Provide Calmness and Comfort",
          Description:
            "Soothing scents help patients feel relaxed and at ease.",
        },
        {
          id: 2,
          title: "Reduce Depression, Stress, and Anxiety",
          Description:
            "Aromas like lavender and chamomile support mental and emotional well-being.",
        },
        {
          id: 3,
          title: "Enhance Perception of Service and Cleanliness",
          Description:
            "Pleasant scents improve patients' views of service quality and facility cleanliness, boosting overall satisfaction.",
        },
      ],
    },
    {
      id: 3,
      title: "Scent in Hotels",
      img: "assets/images/2.jpg",
      description: "",
      list: [
        {
          id: 1,
          title: "Welcome Every Guest with Lasting First Impressions",
          Description:
            "Pleasant scents create a memorable and inviting first impression for guests.",
        },
        {
          id: 2,
          title: "Create New and Enduring Memories",
          Description:
            "Unique aromas help guests form lasting, positive memories associated with their visit.",
        },
        {
          id: 3,
          title: "Enhance Energy and Mood",
          Description:
            "Scents can boost energy and mood by over 40%, contributing to a more enjoyable experience.",
        },
      ],
    },
    {
      id: 4,
      title: "Scent in Malls",
      img: "assets/images/4.jpg",
      description: "",
      list: [
        {
          id: 1,
          title: "Experiential Marketing through Signature Scents",
          Description:
            "Create a unique and memorable shopping experience with distinctive aromas.",
        },
        {
          id: 2,
          title: "Welcome Every Shopper with Lasting First Impressions",
          Description:
            "Use pleasant scents to make a strong, positive initial impact on visitors.",
        },
        {
          id: 3,
          title: "Strengthen Consistency and Signature Elements",
          Description:
            "Reinforce your brand identity with consistent and recognizable scents throughout the mall.",
        },
      ],
    },
    {
      id: 5,
      title: "Scent in Gyms",
      img: "assets/images/5.jpg",
      description:
        "The smell and purity of the air in your facility may not be what first comes to mind, but it is important to your members.",
      description2:
        "At Ambience, we provide fragrance and air purification that will make your space energizing and cleaner, enhancing this often-overlooked part of the customer experience.",
      list: [
        {
          id: 1,
          title: "Experiential Marketing through Signature Scents",
          Description:
            "Create a unique and memorable shopping experience with distinctive aromas.",
        },
        {
          id: 2,
          title: "Welcome Every Shopper with Lasting First Impressions",
          Description:
            "Use pleasant scents to make a strong, positive initial impact on visitors.",
        },
        {
          id: 3,
          title: "Strengthen Consistency and Signature Elements",
          Description:
            "Reinforce your brand identity with consistent and recognizable scents throughout the mall.",
        },
      ],
    },
  ];

  return (
    <>
      <Breadcrumb title="Scenting" />

      <div className="speciality">
        <div className="container">
          <Centing data={sentSapce} />
        </div>
      </div>
    </>
  );
};

export default Scenting;
