import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet } from "react-router";

const Layout = ({ data }) => {
  return (
    <div>
      <Header data={data} />
      <Outlet />
      <Footer data={data} />
    </div>
  );
};

export default Layout;
