import React from "react";

const Centing = ({
  dataitem,
  title,
  img,
  description,
  list,
  button,
}) => {

  return (
    <>
      <div
        className="section-padding  justify-content-between pb-3 pt-3 "
        data-wow-duration="1.1s"
        data-wow-delay="0.01s"
      >
        <div className=" standard-blog-content col-md-10 mx-auto">
          <p>{description}</p>
          <div className="p-0 mt-3">
            {list?.map((item, index) => (
              <>
                {
                  item.img ? (
                    <div className="my-3">
                      <img src={item.img} alt="abc" />
                    </div>
                  ) : (null)
                }

                <p className="mt-3"><b>{item.title}</b> : {item.Description}</p>
              </>
            ))}
          </div>
          <div className="standard-blog-bottom">
            {button
              ? button?.map((item, index) => (
                <a href={item.url} className="btn-style-one">
                  <span>{item.text}</span>
                </a>
              ))
              : null}
            { }
          </div>
        </div>
      </div>
    </>
  );
};

export default Centing;
